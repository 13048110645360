const catchData = {
	parentElm: document.getElementById('topCatch'),
	items: document.querySelectorAll('.js_catch'),
	pager: document.getElementById('catchPager'),
	pagerTop: document.getElementById('catchPagerTop'),
	pagerBottom: document.getElementById('catchPagerBottom'),
	arr: [],
	current: 0,
	moving: 0,
}

const touchObj = (() => {
	const data = {
		touchLastY: 0,
		res: '',
	};
	return {
		jadge: (e) => {
			if (e.type == 'touchstart') {
				data.touchLastY = e.touches[0].clientY;
				return;
			} else {
				if (e.type == 'touchmove') {
					const currentY = e.touches[0].clientY;
					if (currentY < data.touchLastY) {
						data.res = 'down';
					} else {
						if (currentY > data.touchLastY) {
							data.res = 'up';
						} else {
							return;
						}
					}
					data.touchLastY = currentY;
					return data.res;
				}
			}
		}
	}
})();

const wheel = {
	threshold: 35,
	len: 0,
	timer: null,
	timerFunc: () => {
		wheel.len = 0;
	},
	roll: (e) => {
		const deltaY = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.wheelDelta : -(e.detail);
		if (wheel.len > 0) {
			clearTimeout(wheel.timer);
			if (Math.abs(deltaY) < 30) {
				wheel.len++;
				wheel.timer = setTimeout(wheel.timerFunc, wheel.threshold);
			} else {
				wheel.len = 0;
			}
		} else {
			wheel.len = 1;
			wheel.timer = setTimeout(wheel.timerFunc, wheel.threshold);
		}
	},
};

const jadgeDirection = (e) => {
	const down = 'down';
	const up = 'up';
	if (e.type === 'keydown') {
		if (e.keyCode == 40 || e.keyCode == 32) {
			return down;
		}
		if (e.keyCode == 38) {
			return up;
		}
	} else {
		if (e.type === 'wheel') {
			const deltaY = e.deltaY ? -(e.deltaY) : e.wheelDelta ? e.wheelDelta : -(e.detail);
			if (deltaY < 0) {
				return down;
			} else {
				return up;
			}
		} else {
			if (e.type == 'touchmove' || e.type == 'touchstart') {
				return touchObj.jadge(e);
			} else {
				return;
			}
		}
	}
};

const scrollFunc = (e) => {
	const direction = jadgeDirection(e);
	const enterElement = document.querySelector('.enter');

	// クラスenterにshowがついている間はparentElmの処理をしない
	if (enterElement && enterElement.classList.contains('show')) {
		return false;
	}

	if (catchData.parentElm.getBoundingClientRect().top < 0) {
		return false;
	}
	if (!catchData.pager.classList.contains('show')) {
		catchData.pager.classList.add('show');
	}
	if (direction != 'up' && direction != 'down') {
		return false;
	}
	if (catchData.current === catchData.items.length - 1 && direction === 'down' && catchData.moving === 0) {
		catchData.pager.classList.remove('show');
		return false;
	}
	if (e.type == 'wheel' || e.type == 'touchmove' || e.type == 'keydown') {
		e.preventDefault();
	}
	if (e.type == 'wheel') {
		wheel.roll(e);
		if (wheel.len !== 1) {
			return false;
		}
	}
	if (catchData.current === 0 && direction === 'up') {
		return false;
	}
	if (catchData.moving > 0) {
		return false;
	}
	execMove(direction);
}

const execMove = (direction) => {
	const next = (direction === 'up') ? catchData.current - 1 : catchData.current + 1;
	const addClass = (direction === 'up') ? 'under' : 'over';
	const removeClass = (direction === 'up') ? 'over' : 'under';
	catchData.items[catchData.current].classList.remove('show');
	catchData.items[catchData.current].classList.remove(removeClass);
	catchData.items[catchData.current].classList.add(addClass);
	catchData.items[next].classList.add('show');
	catchData.current = next;
	catchData.moving = 2;
	catchData.pagerTop.innerHTML = catchData.current + 1;
}

catchData.pagerBottom.innerHTML = catchData.items.length;

catchData.items.forEach((elm, i) => {
	catchData.arr[i] = {
		elm,
		pos: (i > 0) ? 'over' : 'current',
	}
	elm.addEventListener('transitionend', () => {
		catchData.moving -= 1;
		window.scrollTo(0, 0);
	});
});

window.addEventListener('keydown', scrollFunc, false);
window.addEventListener('wheel', scrollFunc, { passive: false });
window.addEventListener('touchmove', scrollFunc, { passive: false });
window.addEventListener('touchstart', scrollFunc, false);


const swiper = new Swiper('.topSlider', {
	loop: true,
	allowTouchMove: false,
	slidesPerView: 1,
	spaceBetween: 0,
	speed: 6000,
	autoplay: {
		delay: 0,
	},
	breakpoints: {
		480: {
			slidesPerView: 'auto',
		}
	}
});

window.addEventListener('load', function () {
	const enterBlock = document.querySelector('.enter__block');
	const enterBtn = document.querySelector('.enter__btn');
	const enterElement = document.querySelector('.enter');
	const logoImgArea = document.querySelector('.topCatch__imgArea--logo');

	// 動画の再生が開始されてから8秒後にクラスを付ける
	setTimeout(() => {
		enterBlock.classList.add('on');
	}, 8000); // 8秒

	// enter__btnをクリックしたらenter__blockの'on'を削除し、logoに'on'を付ける
	enterBtn.addEventListener('click', function () {
		enterBlock.classList.remove('on');
		enterElement.classList.remove('show');
		enterElement.classList.add('off');
		logoImgArea.classList.add('on');
	});
});



// スクロールを無効にする関数
const disableScroll = () => {
	document.body.style.overflow = 'hidden'; // スクロールを無効化
};

// スクロールを有効にする関数
const enableScroll = () => {
	document.body.style.overflow = ''; // スクロールを元に戻す
};

// クラスenterにshowがついているか監視する関数
const checkEnterClass = () => {
	const enterElement = document.querySelector('.enter');

	if (!enterElement) {
		return; // .enterが存在しない場合は何もしない
	}

	// 初回のチェック
	if (enterElement.classList.contains('show')) {
		disableScroll(); // showクラスがある場合スクロールを無効化
	} else {
		enableScroll();  // showクラスがない場合スクロールを有効化
	}

	// MutationObserverを使用してクラスの変化を監視
	const observer = new MutationObserver(() => {
		if (enterElement.classList.contains('show')) {
			disableScroll(); // showクラスが追加されたらスクロールを無効化
		} else {
			enableScroll();  // showクラスが削除されたらスクロールを有効化
		}
	});

	// クラス属性の変化を監視
	observer.observe(enterElement, { attributes: true, attributeFilter: ['class'] });
};

// DOMが完全に読み込まれた後に関数を実行
document.addEventListener('DOMContentLoaded', checkEnterClass);



// クラスenterにoffがついた状態をセッションストレージに保存する関数
const saveOffState = () => {
	sessionStorage.setItem('enterOffState', 'true'); // "off"クラスがついたことをセッションストレージに保存
};

// クラスenterにoffがついているかをセッションストレージから読み込み、設定する関数
const loadOffState = () => {
	const enterElement = document.querySelector('.enter');

	if (!enterElement) return; // enter要素が存在しない場合は何もしない

	const isOff = sessionStorage.getItem('enterOffState');
	const logoImgArea = document.querySelector('.topCatch__imgArea--logo');

	if (isOff === 'true') {
		enterElement.classList.remove('show'); // "show"クラスを削除
		enterElement.classList.add('off'); // "off"クラスを追加
		enterElement.classList.add('off'); // "off"クラスを追加
		logoImgArea.classList.add('on');
	}
};

// クラスenter__btnをクリックしたときにクラスenterのshowをoffに変更し、状態を保存
document.addEventListener('DOMContentLoaded', () => {
	const enterElement = document.querySelector('.enter');
	const enterBtn = document.querySelector('.enter__btn');

	// ページ読み込み時にoff状態を反映
	loadOffState();

	// enter__btnがクリックされたらクラス変更と状態保存
	if (enterElement && enterBtn) {
		enterBtn.addEventListener('click', () => {
			if (enterElement.classList.contains('show')) {
				enterElement.classList.remove('show');
				enterElement.classList.add('off');
				saveOffState(); // "off"クラスがついたことをセッションストレージに保存
			}
		});
	}
});
